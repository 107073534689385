
const digest = '69255ca4ab3f68bf2aadb63e15ecddb7ba69155a980dbb8a3bd4013af2403643';
const css = `._grid_bihe2_1 {
  display: flex;
  grid-column: span 2;
  margin-left: -4px;
}

._color_bihe2_7 {
  cursor: pointer;
  flex-grow: 2;
  border: none;
  height: 24px;
  width: 100%;
  padding: none;
}

._selected_bihe2_16 {
  border-bottom: 5px solid dodgerblue;
}
`;

(function() {
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"grid":"_grid_bihe2_1","color":"_color_bihe2_7","selected":"_selected_bihe2_16"};
export { css, digest };
  