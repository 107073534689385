
const digest = '91426e1055520f4c37a3f87a9cc4662d5a43d1698756f8bebfdbc956fa5f2d36';
const css = `._container_1l8m7_1 {
  position: fixed;
  width: min(100%, 320px);
  top: 48px;
  left: 0px;
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.95);
  border-bottom-right-radius: 16px;
  box-shadow: 0px 0px 8px -4px rgba(0, 0, 0, 0.16),
    0px 0px 36px 0px rgba(0, 0, 0, 0.08);
  z-index: 100;
  transform: translate(-100%, 0px);
}

._open_1l8m7_15 {
  transform: translate(0%, 0px);
  transition: transform 0.2s;
}

._inputs_1l8m7_20 {
  display: grid;
  grid-template-columns: 96px 1fr auto;
  grid-auto-rows: 32px;
  align-items: center;
  column-gap: 16px;
}

._colors_1l8m7_28 {
  padding: 12px 0;
}

hr {
  grid-column: 1 / span 3;
  height: 1px;
  border: none;
  background-color: gainsboro;
  width: calc(100% + 24px);
  margin-left: -12px;
}

._buttonsRow_1l8m7_41 {
  display: flex;
  height: 40px;
}

._rowButton_1l8m7_46 {
  cursor: pointer;
  flex-grow: 2;
  font-family: 'Recursive', sans-serif;
  border: none;
  background-color: transparent;
  border: 2px solid transparent;
}

._rowButton_1l8m7_46:hover {
  color: dodgerblue;
}

._rowButton_1l8m7_46:focus {
  outline: none;
  border: 2px solid dodgerblue;
}
`;

(function() {
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"container":"_container_1l8m7_1","open":"_open_1l8m7_15","inputs":"_inputs_1l8m7_20","colors":"_colors_1l8m7_28","buttonsRow":"_buttonsRow_1l8m7_41","rowButton":"_rowButton_1l8m7_46"};
export { css, digest };
  