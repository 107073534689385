
const digest = '8553ea87e75ef9c13e30894c372609ef7d858faeba8b812f5bbbd50df4f02ed5';
const css = `._select_1eg20_1 {
  grid-column: 2 / span 2;
  padding: 0;
}

._select_1eg20_1 select {
  position: relative;
  display: flex;
  height: 30px;
  width: calc(100% + 6px);
  background: none;
  border: none;
  cursor: pointer;
  border-radius: none;
  padding: 0;
  margin-left: -6px;
  outline: none;
  font: inherit;
  border: 2px solid transparent;
}

._select_1eg20_1 select:focus {
  outline: none;
  border: 2px solid dodgerblue;
}
`;

(function() {
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"select":"_select_1eg20_1"};
export { css, digest };
  