
const digest = '9e693654bcd3a55eae2d545b75e308338fb93e4684411a2fa6e66ad38acbbf9b';
const css = `._root_1bglo_1 {
  max-width: 200px;
  height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 1;
  user-select: none;
  touch-action: none;
}

._range_1bglo_13 {
  position: absolute;
  height: 3px;
  background-color: dodgerblue;
  border-radius: 5px;
}

._track_1bglo_20 {
  position: relative;
  height: 3px;
  flex-grow: 1;
  background-color: gainsboro;
  border-radius: 5px;
}

._thumb_1bglo_28 {
  position: relative;
  height: 4px;
  width: 4px;
  outline: none;
}

._thumbBall_1bglo_35 {
  display: block;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

._thumbBall_1bglo_35::after {
  display: block;
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: white;
  border: 2px solid dodgerblue;
  transform: scale(1);
  transition: all 0.12s;
}

._thumb_1bglo_28:focus > ._thumbBall_1bglo_35::after {
  background-color: dodgerblue;
}

._numberInput_1bglo_60 {
  font-family: inherit;
  font-size: 12px;
  border: none;
  width: 40px;
  text-align: right;
  padding: 4px 4px;
}

._numberInput_1bglo_60::-webkit-outer-spin-button,
._numberInput_1bglo_60::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

._numberInput_1bglo_60:focus {
  border-radius: 2px;
  outline: 2px solid dodgerblue;
}
`;

(function() {
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"root":"_root_1bglo_1","range":"_range_1bglo_13","track":"_track_1bglo_20","thumb":"_thumb_1bglo_28","thumbBall":"_thumbBall_1bglo_35","numberInput":"_numberInput_1bglo_60"};
export { css, digest };
  