
const digest = 'c899bf9ffb6f5ef99f9a21b8d1defcd3531f6d65ea20d15f4270d317b4f4ced0';
const css = `._container_13606_1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
`;

(function() {
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"container":"_container_13606_1"};
export { css, digest };
  