
const digest = '4c3369704f2cf5b18a53b6ad2c8cf2b8f8233c4def5968179cd7cfbd91f8b9a9';
const css = `._container_tfbz8_1 {
  position: absolute;
  display: flex;
  z-index: 20;
  width: fit-content;
  user-select: none;
}

._container_tfbz8_1 a,
._container_tfbz8_1 button {
  border: none;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-family: 'Recursive', sans-serif;
  user-select: none;
  background-color: rgba(255, 255, 255, 1);
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 1),
    1px 1px 3px rgba(255, 255, 255, 1), 1px 1px 8px rgba(255, 255, 255, 1);
}

._container_tfbz8_1 button {
  padding: 16px 40px;
}

._container_tfbz8_1 a:hover,
._container_tfbz8_1 button:hover,
._container_tfbz8_1 button[data-active='true'] {
  color: dodgerblue;
  background-color: white;
}

._bottom_tfbz8_36 {
  bottom: 0px;
}

._left_tfbz8_40 {
  left: 0px;
}

._right_tfbz8_44 {
  right: 0px;
}

._top_tfbz8_48 {
  top: 0px;
}

._center_tfbz8_52 {
  position: fixed;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none;
  z-index: 10;
}

._center_tfbz8_52 a {
  color: black;
  text-decoration: none;
}
`;

(function() {
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"container":"_container_tfbz8_1","bottom":"_bottom_tfbz8_36","left":"_left_tfbz8_40","right":"_right_tfbz8_44","top":"_top_tfbz8_48","center":"_center_tfbz8_52"};
export { css, digest };
  