
const digest = '8a87aba432309d13558f7d6368d3bd6b7a0481cab4e001944e99f418ee8d5a6f';
const css = `._root_hcu64_1 {
  position: relative;
  flex-grow: 2;
  max-width: 200px;
  display: flex;
  height: 16px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

._root_hcu64_1:disabled {
  opacity: 0.5;
}
._root_hcu64_1:disabled::after {
  background-color: gainsboro;
  opacity: 1;
}

._root_hcu64_1:focus {
  outline: none;
}

._root_hcu64_1:focus::after {
  border: 2px solid dodgerblue;
}

._root_hcu64_1::after {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: 16px;
  height: 16px;
  border: 1px solid gainsboro;
  border-radius: 2px;
  box-sizing: border-box;
}

._indicator_hcu64_42 {
  position: initial;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  box-sizing: border-box;
  background-color: dodgerblue;
  border: 1px solid dodgerblue;
  z-index: 2;
}
`;

(function() {
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"root":"_root_hcu64_1","indicator":"_indicator_hcu64_42"};
export { css, digest };
  